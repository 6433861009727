<template>
  <div class="tracesource-result">
    <div class="tracesource-result-header">
      <div class="goback" @click="goBack">
        <i class="back"></i>返回
      </div>
      <div class="history" @click="$router.push({ path : '/traceSourceHistory' })">历史溯源</div>
    </div>
    <div class="tracesource-result-content" v-html="content"></div>
    <div class="tracesource-result-report">
      <div class="report-title">
        <span class="title">溯源报告</span>
      </div>
      <div class="report-info">
        <div class="report-info-original">
          <i
          class="report-info-original-icon"
          :class="
          traceSourceData.level == 'A+'
          ? 'Aadd'
          : traceSourceData.level == 'A'
          ? 'A'
          : traceSourceData.level == 'B+'
          ? 'Badd'
          : traceSourceData.level == 'B'
          ? 'B'
          : 'C'"></i>
          <div class="report-info-original-text">
            严格原创率{{ traceSourceData.dupScale }}%
          </div>
          <el-progress
            :text-inside="false"
            :stroke-width="15"
            :percentage="progress"
          >
          </el-progress>
        </div>
        <div class="report-info-textGroup">
          <div class="report-info-text">
            <div class="report-info-label">溯源编码：</div>
            <div class="report-info-content">
              {{ traceSourceData.soleCode }}
            </div>
          </div>
          <div class="report-info-text">
            <div class="report-info-label">溯源核验码：</div>
            <div class="report-info-content">
              {{ traceSourceData.reportCode }}
            </div>
          </div>
          <div class="report-info-text">
            <div class="report-info-label">溯源时间：</div>
            <div class="report-info-content">
              {{ traceSourceData.createTime }}
            </div>
          </div>
        </div>
        <el-collapse v-model="activeNames">
          <el-collapse-item
          v-for="(item, index) in traceSourceData.report"
          :title="item.title"
          :name="item.id"
          :key="index">
            <div v-for="(child, index) in item.content" :key="index">
              <span class="serialNum">{{ index + 1 }}</span>
              <span class="contentTitle">{{ child.title }}</span>
              <span
              class="empower"
              v-if="child.empower && child.empower == true"
              @click="authorization(child)"
              >可在线授权</span>
              <span class="empowerNo" v-else>无在线授权</span>
              <span class="num">+{{ child.number }}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <!--溯源成功提示-->
    <transition name="el-fade-in-linear">
      <div class="report-tips" v-if="dialogSwitch">
        <div class="report-tips-content">
          <i class="report-tips-content-icon"></i>
          <div class="report-tips-content-text">
            溯源已完成，溯源报告存放于个人“<span
              >存证网 盘/系统文件/溯源报告</span
            >”，官方存储系统不另行保存。
          </div>
        </div>
      </div>
    </transition>
    <TraceSourceAuthorizationInfo ref="traceAuthorize"/>
  </div>
</template>

<script>
import { getTraceSourceResult } from "api/traceTheSource";
import TraceSourceAuthorizationInfo from "components/traceSource/TraceSourceAuthorizationInfo";
export default {
  name : "TraceSourceResult",
  data(){
    return {
      //溯源结果内容
      content : "",
      //溯源结果数据
      traceSourceData : "",
      //溯源计时器id
      source_timer : null, 
      //折叠面板索引参数
      activeNames: [1, 2, 3],
      //溯源成功提示
      dialogSwitch : false,
      //文章md5
      textMd5 : "",
      //溯源进度
      progress : 0
    }
  },
  methods : {
    //返回
    goBack(){
      this.$router.go(-1);
    },
    //获取溯源结果
    getTraceSourceResult() {
      let count = 0;
      let renderingData = (res) => {
        this.content = res.data.joinWord;
        this.traceSourceData = res.data;
        this.progress = this.traceSourceData.progress
          ? this.traceSourceData.progress > 100
            ? 100
            : +this.traceSourceData.progress
          : 0;
        this.traceSourceData.report &&
        this.traceSourceData.report.map((item) => {
          if (item.id == 1) {
            item.title = `一、${item.title} ${item.repeatRate}%`;
          } else if (item.id == 2) {
            item.title = `二、${item.title} ${item.repeatRate}%`;
          } else {
            item.title = `三、${item.title} ${item.repeatRate}%`;
          }
        });
        if (
          this.traceSourceData.progress == 100 ||
          this.traceSourceData.progress > 100
        ) {
          clearInterval(this.source_timer);
        }
      };
      const last = Date.now();
      //获取溯源结果
      this.source_timer = setInterval(() => {
        count++;
        getTraceSourceResult()
        .then((res) => {
          if (res.code == 200) {
            renderingData(res);
            setTimeout(() => {
              this.dialogSwitch = true;
              setTimeout(() => {
                this.dialogSwitch = false;
              }, 2000);
            }, 500);
            clearInterval(this.source_timer);
          } else if (res.code == 8001) {
            renderingData(res);
          } else if (res.code == 8002) {
            this.$message({
              type: "error",
              message: "当前溯源未在执行中,请尝试重新开始溯源",
            });
            clearInterval(this.source_timer);
          }
          const now = Date.now();
          if (now - last > 1000 * 60 * 5) {
            this.$message({
              type: "warning",
              message: "当前溯源已超时",
            });
            clearInterval(this.source_timer);
          }
        })
        .catch((e) => {
          clearInterval(this.source_timer);
        });
      }, 1000);
    },
    //授权
    authorization(item){
      this.$refs["traceAuthorize"].authorization(item);
    }
  },
  components : {
    TraceSourceAuthorizationInfo
  },
  created(){
    this.getTraceSourceResult();
  },
  destoryed(){
    clearInterval(this.source_timer);
  }
}
</script>

<style lang="scss" scoped>
.tracesource-result{
  min-width:644px;
  min-height:550px;
  // height:100vh;
  padding-bottom:20px;
  box-sizing:border-box;
  &-header{
    @include flex-between;
    padding:14px 12px;
    .goback{
      @include flex-start;
      @include noselect;
      position:relative;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      .back {
        position: relative;
        width: 6.3px;
        height: 11px;
        margin-right:3px;
        margin-bottom:1px;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: url("~assets/images/arrow-left.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .history{
      @include noselect;
      width: 92px;
      height: 28px;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(162, 170, 184, 0.38);
      border-radius: 14px;
      text-align:center;
      line-height:28px;
      font-size: 13px;
      font-weight: 500;
      color: #4587FF;
      cursor:pointer;
    }
  }
  &-content{
    margin-top:8px;
    padding:0 30px;
  }
  &-report{
    padding:0 30px;
    .report-title{
      @include flex-center;
      margin-top:34px;
      .title{
        position:relative;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        &::before,&::after{
          content:"";
          position:absolute;
          width:131px;
          height:1px;
          background-color:#F3F5F6;
          top:6.5px;
        }
        &::before{
          left:-152px;
        }
        &::after{
          right:-152px;
        }
      }
    }
    .report-info {
      position: relative;
      margin-top:32px;
      &-original {
        @include flex-start;
        &-icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .Aadd {
          @include backgroundGroup("../../assets/images/A+.png");
        }
        .A {
          @include backgroundGroup("../../assets/images/A.png");
        }
        .Badd {
          @include backgroundGroup("../../assets/images/B+.png");
        }
        .B {
          @include backgroundGroup("../../assets/images/B.png");
        }
        .C {
          @include backgroundGroup("../../assets/images/c.png");
        }
        &-text {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          margin-right: 20px;
        }
        ::v-deep .el-progress {
          @include flex-start;
          width: 187px;
        }
        ::v-deep .el-progress__text {
          font-size: 14px !important;
        }
      }
      .report-info-textGroup {
        margin-top:18px;
        .report-info-text {
          display: flex;
          margin-top: 10px;
          .report-info-label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-gray;
          }
          .report-info-content {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
        }
      }
      ::v-deep .el-collapse {
        border: none;
        margin-top:23px;
        .el-collapse-item__arrow {
          margin: 0 8px 0;
        }
        .el-collapse-item__header {
          border-bottom: none;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: bold;
          color: $color-text-black;
        }
        .el-collapse-item__wrap {
          border-bottom: none;
        }
        .el-collapse-item__content {
          height: auto;
          max-height: 375px;
          overflow-y: scroll;
          padding-bottom: 0;
          > div {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-gray;
            display: flex;
            align-items: center;
            .serialNum {
              display: inline-block;
              width: 20px;
            }
            .contentTitle {
              display: inline-block;
              @include ellipsis();
              width: 320px;
            }
            .empowerNo {
              color: $color-text-gray;
              text-decoration: underline;
            }
            .empower {
              color: $color-bg-heightlight;
              text-decoration: underline;
              cursor: pointer;
            }
            .num {
              display: inline-block;
              margin-left: 5px;
              color: #eb441e;
            }
          }
        }
      }
    }
  }
  //溯源成功提示
  .report-tips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 391px;
    height: 103px;
    background: #ffffff;
    border: 1px solid #4587ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      display: flex;
      margin: 0 20px;
      .report-tips-content-icon {
        width: 42px;
        height: 21px;
        margin-right: 10px;
        @include backgroundGroup("../../assets/images/PromptSuccessful.png");
        transform: translateY(4px);
      }
      .report-tips-content-text {
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
    }
  }
}
</style>